// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\Development\\tenta.me\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("C:\\Development\\tenta.me\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-licenses-js": () => import("C:\\Development\\tenta.me\\src\\pages\\licenses.js" /* webpackChunkName: "component---src-pages-licenses-js" */),
  "component---src-pages-privacypolicy-js": () => import("C:\\Development\\tenta.me\\src\\pages\\privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-support-js": () => import("C:\\Development\\tenta.me\\src\\pages\\support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-termsofuse-js": () => import("C:\\Development\\tenta.me\\src\\pages\\termsofuse.js" /* webpackChunkName: "component---src-pages-termsofuse-js" */)
}

