module.exports = [{
      plugin: require('C:/Development/tenta.me/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-188867262-1"},
    },{
      plugin: require('C:/Development/tenta.me/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":900},
    },{
      plugin: require('C:/Development/tenta.me/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
